import React, { Component } from 'react';

class GraphInput extends Component {
	constructor(props) {
		super(props);
		this.state = {
			from: '',
			to: '',
		};
		this.graphRegex = /^[a-zA-Z]+$/;
	}

	onChange = (value, stateValue) => {
		if (value === '' || (value.length > -1 && value.length < 4 && this.graphRegex.test(value))) {
			if (stateValue === 'from') this.setState({ from: value });
			if (stateValue === 'to') this.setState({ to: value });
		}
	};

	onClick = async () => {
		const response = await fetch(`/api/graph?from=${this.state.from}&to=${this.state.to}`, {
			method: 'POST',
		});
		if (response.status !== 200) console.log('some error occured');
		else this.setState({ from: '', to: '' });
	};

	render() {
		return (
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<h1>Grafdemo</h1>
				<p>Skriv din bruker i fra-feltet og bruker til den du kjenner i til-felter.</p>
				<p>Brukernavn finner du i søk på Teams og Outlook.</p>
				<p>Eksempel: [fra: OLI til: LHA]. Ola Lium(OLI) kjenner Lars Erik Hamre(LHA)</p>
				<br></br>
				<input
					value={this.state.from}
					onChange={(e) => this.onChange(e.target.value, 'from')}
					placeholder="fra"
				/>
				<input value={this.state.to} onChange={(e) => this.onChange(e.target.value, 'to')} placeholder="til" />
				<button
					disabled={this.state.from.length < 1 || this.state.to.length < 1}
					onClick={() => this.onClick()}
				>
					Send
				</button>
			</div>
		);
	}
}

export default GraphInput;

